/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type bookingsByMonthGetPdfQueryVariables = {|
  id: string,
  documentType: string,
  number?: ?number,
|};
export type bookingsByMonthGetPdfQueryResponse = {|
  +booking: ?{|
    +getOne: ?{|
      +pdf: ?{|
        +getPdf: ?string
      |}
    |}
  |}
|};
export type bookingsByMonthGetPdfQuery = {|
  variables: bookingsByMonthGetPdfQueryVariables,
  response: bookingsByMonthGetPdfQueryResponse,
|};
*/


/*
query bookingsByMonthGetPdfQuery(
  $id: String!
  $documentType: String!
  $number: Int
) {
  booking {
    getOne(id: $id, type: "REF") {
      pdf {
        getPdf(documentType: $documentType, number: $number)
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "number"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "REF"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PdfQuery",
  "kind": "LinkedField",
  "name": "pdf",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "documentType",
          "variableName": "documentType"
        },
        {
          "kind": "Variable",
          "name": "number",
          "variableName": "number"
        }
      ],
      "kind": "ScalarField",
      "name": "getPdf",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "bookingsByMonthGetPdfQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BookingQuery",
        "kind": "LinkedField",
        "name": "booking",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Booking",
            "kind": "LinkedField",
            "name": "getOne",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "bookingsByMonthGetPdfQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BookingQuery",
        "kind": "LinkedField",
        "name": "booking",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Booking",
            "kind": "LinkedField",
            "name": "getOne",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a7b603c2082ee9a60b8de08a286222e",
    "id": null,
    "metadata": {},
    "name": "bookingsByMonthGetPdfQuery",
    "operationKind": "query",
    "text": "query bookingsByMonthGetPdfQuery(\n  $id: String!\n  $documentType: String!\n  $number: Int\n) {\n  booking {\n    getOne(id: $id, type: \"REF\") {\n      pdf {\n        getPdf(documentType: $documentType, number: $number)\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c008b1bc6c9e24a897ba3971e4fced54';

module.exports = node;
