/* eslint-disable max-len, no-unsafe-optional-chaining */
import { useState, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useTranslation } from '@/helpers/translate';
import { round } from '@/helpers/common.js';
import Pagination from './pagination.js';
import { DropDown, Select } from '@/core/dynamic';
import Icon from '@/core/icon';
import Space from './space.js';
import Emitter from '@/helpers/emitter.js';
import { useZusModalStore } from '@/helpers/modal.js';
import miniQLWS from '@/helpers/miniQueryLoaderv3.js';
import dayjs from '@/helpers/dayjs';

const limitBooking = 3;

const PdfQuery = graphql`
  query bookingsByMonthGetPdfQuery($id: String!, $documentType: String!, $number: Int) {
    booking {
      getOne(id: $id, type:"REF") {
        pdf {
          getPdf(documentType: $documentType, number: $number)
        }
      }
    }
  }
`;

function DownloadPdf({
 data, bookingRef, documentType, callback,
}) {
  useEffect(() => {
    if (data) {
      const { booking } = data;
      if (booking?.getOne?.pdf?.getPdf) {
        const link = document.createElement('a');
        link.href = booking.getOne.pdf.getPdf;
        link.download = `${documentType}_${bookingRef}.pdf`;
        link.style.display = 'none'; // Cacher le lien
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    if (callback) {
      callback();
    }
    return () => {
      if (callback) {
        callback();
      }
    };
  }, [data]);
  return null;
}

function RowBooking({ booking, index }) {
  const { t } = useTranslation();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [spaceOpen, setSpaceOpen] = useState(null);

  const onClickOpen = (indexBk) => {
    if (spaceOpen === indexBk) {
      return setSpaceOpen(null);
    }

    return setSpaceOpen(index);
  };

  const emitterOnCloseSpace = (indexBk) => {
    if (index !== indexBk) setSpaceOpen(null);
  };

  useEffect(() => {
    Emitter.on('emitterWhichSpaceIndexClose', emitterOnCloseSpace);
    return () => {
      Emitter.off('emitterWhichSpaceIndexClose', emitterOnCloseSpace);
    };
  }, []);

  const downloadPdf = ({ documentType, number }) => {
    setLoadingPdf(true);
    const { setModal3 } = useZusModalStore.getState();
    const ComponentDLPDF = miniQLWS({
      Component: DownloadPdf,
      query: PdfQuery,
      providerVariables: ({ id, documentType: pvdDocType, number: pvdNumber }) => ({
 id, documentType: pvdDocType, whois: 'rider', ...(pvdNumber > 0 ? { number: pvdNumber } : {}),
}),
    });
    setModal3(<ComponentDLPDF
      callback={() => {
        setLoadingPdf(false);
        setModal3(null);
      }}
      {...{
        id: booking.ref,
        documentType,
        bookingRef: booking.ref,
        ...(number > 0 ? { number } : {}),
      }}
    />);
  };
  return (
    <>
      <div
        id={`tr${booking.ref}`}
        onClick={() => {
          onClickOpen(index);
          Emitter.emit('emitterWhichSpaceIndexClose', index);
        }}
        className={`p-2 transition cursor-pointer text-[13px] col-span-12 items-center lg:grid grid-cols-2 lg:grid-cols-12 w-full mt-1 bg-white-dark ${spaceOpen === index ? 'rounded-t-lg' : 'rounded-lg hover:bg-white-dark2 hover:text-black'} cursor-pointer`}
      >
        <div className="col-span-2 lg:pl-1 lg:py-2 ">{dayjs(booking?.endAt).format('DD MMM YYYY')}</div>
        <div className="col-span-2 lg:py-2">{booking?.dates?.text}</div>
        <div className="col-span-2 lg:py-2">{booking?.rider?.fullUserName}</div>
        <div className="hidden col-span-1 lg:block lg:py-2">{round(booking?.corpoBill?.prices?.totalWithFees?.exclTax)}€</div>
        <div className="hidden col-span-1 lg:block lg:py-2">{round(booking?.corpoBill?.prices?.totalWithFees?.inclTax)}€</div>
        {/* <div className="hidden col-span-2 lg:block lg:py-2">{round(booking?.current?.prices?.totalWithFees?.inclTax)}€</div> */}
        <div className="col-span-2 lg:py-2">{[...new Set((booking?.transactions || []).map(({ paymentMethod }) => t(`corpo:${paymentMethod}`)))].join(',')}</div>
        <div className="col-span-2" onClick={(event) => event?.stopPropagation?.()}>
        {!booking?.pdf?.rider?.list?.length || (
          <Select
            options={[
            {
              value: '',
              content: <span className="block py-1 pl-3 pr-4 text-sm font-medium text-dark hover:text-black" role="menuitem">{t('corpo.pdf.title')}</span>,
            }, ...booking?.pdf?.rider?.list?.map(({ slug, param, text }) => ({
              value: `${slug}_${param}_${text}`,
              valueData: {
                slug, param,
              },
              content: <span className="block py-1 pl-3 pr-4 text-sm text-dark hover:text-black" role="menuitem">{text}</span>,
            }))]}
            onSelected={({ valueData }) => {
            if (valueData?.slug) {
              downloadPdf({
                documentType: valueData?.slug,
                number: valueData?.param,
              });
            }
          }}
          />
        )}
        </div>
      </div>
      {spaceOpen === null || (
        <div className="flex w-full px-3 pb-3 rounded-b-lg bg-white-dark">
            <Space spaceRef={booking.spaceRef} />
        </div>
      )}
      {/*
        <tr id={`tab${booking.id}`}className={`${isOpen === index ? 'visible border-b-white-dark4 show  border-b' : 'hidden border-b-0'} `}>
          <td colSpan="6">
            <div className="px-2 pb-6 sm:grid sm:grid-cols-8 sm:gap-x-2 group">
              <div className="h-40 overflow-hidden rounded-lg sm:col-span-2 sm:h-34 md:h-44 group">

                <div className="h-full rounded-lg embla__slide__inner">
                  <Image
                    className="h-full cursor-pointer carousel-cell-search bg-white-dark2"
                    picture={booking.spaceData.picture}

                  />
                </div>

              </div>
              <div className="h-full pt-2 sm:col-span-6 sm:ml-4 sm:pt-4 sm:py-4 sm:relative">
                <div className="flex flex-col items-start justify-center w-full h-full grow">

                  <h2 className="mr-10 text-base truncate sm:whitespace-normal sm:mr-0 sm: text-black-light sm:text-black sm:text-lg">{booking.spaceData.name}</h2>
                  <p className="mr-8 text-sm text-dark">{booking.spaceData.adresse}</p>

                  <div className="text-sm sm:items-center sm:flex sm:justify-between text-dark sm:pt-3">

                    <div className="items-center pr-5 mr-5 sm:flex sm:border-r sm:border-white-dark4">
                      <Icon className="w-3 h-3 mr-1 fill-current text-dark" name="man" />
                      {booking.spaceData.nbParticipants}
                      {' '}
                      {t('corpo:activities.participants')}
                    </div>

                    <div className="items-center hidden pr-5 mr-5 sm:flex sm:border-r sm:border-white-dark4">
                      {booking.spaceData.type}
                    </div>

                    <div className="absolute flex items-center text-base sm:text-sm bottom-4 right-6 sm:static">
                      <Icon className="fill-current text-blue" name="star" />
                      <span className="mx-1 text-blue">{booking.spaceData.avis}</span>
                      (
                      {booking.spaceData.nbAvis}
                      )
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      */}
    </>
  );
}
function RowContent({ open, item }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentBooking, setCurrentBooking] = useState(item.bookings.slice(currentPage * limitBooking, (currentPage + 1) * limitBooking));
  const { t } = useTranslation();

  const fnNewPage = (page) => {
    setCurrentPage(page);
    setCurrentBooking(item.bookings.slice(page * limitBooking, (page + 1) * limitBooking));
  };

  useEffect(() => {
    if (open && currentPage > 0) {
      fnNewPage(0);
    }
  }, [open]);

  return (
    <div>
      {open && (
        <div className="show">
          <div className="w-full px-3 gap-x-3">
            <div className="flex-col hidden ld:flex lg:grid lg:grid-cols-12 lg:gap-y-2">
              <div className="col-span-2 text-xs truncate text-dark-light lg:pt-3 lg:pl-2">{t('corpo:booking.invoicedate')}</div>
              <div className="col-span-2 text-xs truncate text-dark-light lg:pt-3 lg:pl-2">{t('corpo:booking.bookingdate')}</div>
              <div className="col-span-2 text-xs truncate text-dark-light lg:pt-3">{t('corpo:activities.team')}</div>
              <div className="col-span-1 text-xs truncate text-dark-light lg:pt-3">{t('corpo:price.exclTax')}</div>
              <div className="col-span-1 text-xs truncate text-dark-light lg:pt-3">{t('corpo:price.inclTax')}</div>
              {/* <div className="col-span-2 text-xs truncate text-dark-light lg:pt-3">{t('corpo:price.remain')}</div> */}
              <div className="col-span-2 text-xs truncate text-dark-light lg:pt-3">{t('corpo:paymentMode.title')}</div>
            </div>
            <div className="flex flex-col col-span-12">
              {currentBooking.map((booking, index) => (
                <RowBooking {...{ booking, index }} />
              ))}
            </div>
          </div>
          <Pagination
            onChange={({ page }) => {
              fnNewPage(page);
            }}
            total={Math.ceil(item.bookings.length / limitBooking)}
          />
        </div>
      )}
    </div>
  );
}

function RowItem({
  children, item, toggleRow, open,
}) {
  const { t } = useTranslation();
  const classes = open ? 'pb-3 flex-col rounded-lg bg-white shadow-button ' : 'rounded-lg bg-white-dark hover:bg-white-dark2 items-center flex border-white hover:border-white-dark2';

  const [total] = useState((item.bookings || []).reduce((acc, bk) => {
    const newAcc = { ...acc };
    newAcc.nb += 1;
    newAcc.exclTax += round(bk.corpoBill?.prices?.totalWithFees?.exclTax);
    newAcc.inclTax += round(bk.corpoBill?.prices?.totalWithFees?.inclTax);
    newAcc.remainInclTax += round(bk.current?.prices?.totalWithFees?.inclTax);
    newAcc.remainExclTax += round(bk.current?.prices?.totalWithFees?.exclTax);
    return newAcc;
  }, {
   nb: 0, exclTax: 0, inclTax: 0, remainInclTax: 0, remainExclTax: 0,
  }));

  const formatkeyMonth = (keyMonth) => {
    const keyMonthSplit = keyMonth.split('_');
    return `${t(`date.month_short.${keyMonthSplit[1] - 1}`)} ${keyMonthSplit[0]}`;
  };

  return (
    <li className={`w-full bg-white rounded-lggap-y-2 ${classes}`}>
      <div onClick={toggleRow} className="flex items-stretch w-full px-1 py-3 text-sm transition cursor-pointer text-dark-deep hover:text-black lg:text-sm no-wrap lg:py-3 lg:px-0">
        <div className="flex items-center w-full px-1 font-medium lg:px-3">{formatkeyMonth(item.keyMonth)}</div>
        <div className="flex items-center w-full px-1 font-medium lg:px-3">{total.nb}</div>
        <div className="flex items-center w-full px-1 font-medium lg:px-3">{round(total.exclTax)}€</div>
        <div className="flex items-center w-full px-1 font-medium lg:px-3">{round(total.inclTax)}€</div>
        {/* <div className="flex items-center w-full px-1 lg:px-3">[todefine]</div> */}
      </div>
      <RowContent open={open} item={item} />
      {children}

    </li>
  );
}

export default RowItem;
