import { graphql } from 'react-relay';
import { lang } from '@/helpers/translate.js';
import { SpaceCardSkinCorpo } from '@/core/dynamic';
import Loading from '@/core/loading.js';
import miniQLWS from '@/helpers/miniQueryLoaderv3.js';

const query = graphql`
  query spaceCorpoActivitiesQuery ($lang: String!, $ref: String!) {
    one: getSpaceByRef(ref: $ref) {
      ...cardSkinCorpo_item @arguments(lang: $lang)
    }
  }
`;

function SpaceContainer({ data }) {
  return <SpaceCardSkinCorpo item={data.one} />;
}

export default miniQLWS({
  Component: SpaceContainer,
  query,
  providerVariables: ({ spaceRef }) => ({
    ref: spaceRef,
    lang: lang(),
  }),
});

/*

function SpaceContainer({ spaceRef }) {
  const variables = { lang: lang(), ref: spaceRef };
  const options = { fetchPolicy: 'network-only', networkCacheConfig: { force: true } };

  if (isLoading) return <Loading />;
  return <SpaceCardSkin1 item={data.one} />;
}

*/
