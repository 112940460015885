import { useState, memo } from 'react';
import ReactPaginate from 'react-paginate';
import Emitter from '@/helpers/emitter.js';
import Icon from '@/core/icon';

const CorpoMonthPagination = memo(({ emitcode, total, onChange }) => {
  const [pageCurrent, setPageCurrent] = useState(0);

  const fnChangePage = (page) => {
    setPageCurrent(page);
    if (emitcode) {
      Emitter.emit(emitcode, { page });
    } else if (onChange) {
      onChange({ page });
    }
  };

  if (!total || total < 2) return null;

  return (
    <ReactPaginate
      className="flex items-center justify-center pt-4 pb-1 space-x-3"
      breakLabel="..."
      forcePage={pageCurrent}
      nextLabel={<span className="flex items-center justify-center transition rounded-full w-7 h-7 hover:bg-white-dark text-dark hover:text-black group"><Icon className="w-4 h-4 text-dark group-hover:text-black" name="next" /></span>}
      onPageChange={({ ...args }) => {
        fnChangePage(args.selected);
      }}
      pageRangeDisplayed={3}
      pageCount={total}
      previousLabel={<span className="flex items-center justify-center transition rounded-full w-7 h-7 hover:bg-white-dark hover:text-black group"><Icon className="w-4 h-4 transform rotate-180 group-hover:text-black" name="next" /></span>}
      renderOnZeroPageCount={null}
      activeClassName="text-sm flex items-center justify-center block text-black font-medium w-7 h-7"
      pageLinkClassName="rounded-full transition block w-full h-full text-center align-middle pt-1 text-black hover:text-black"
      activeLinkClassName="hover:text-black text-black"
      disabledLinkClassName="invisible"
      pageClassName="transition text-sm flex items-center justify-center w-7 h-7 block hover:bg-white-dark hover:text-black rounded-full group"
    />
  );
});

export default CorpoMonthPagination;
