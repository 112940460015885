/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentMethod = "cb" | "transfer" | "%future added value";
export type activitiesCardQueryVariables = {|
  options?: ?any
|};
export type activitiesCardQueryResponse = {|
  +bookingGetByCorpoRef: ?{|
    +metadata: ?any,
    +data: ?$ReadOnlyArray<?{|
      +keyMonth: ?string,
      +bookings: ?$ReadOnlyArray<?{|
        +ref: string,
        +dates: ?any,
        +rider: {|
          +fullUserName: ?string
        |},
        +endAt: ?any,
        +spaceRef: ?string,
        +transactions: ?$ReadOnlyArray<?{|
          +paymentMethod: ?PaymentMethod
        |}>,
        +pdf: ?{|
          +rider: ?{|
            +list: ?$ReadOnlyArray<?{|
              +slug: ?string,
              +text: ?string,
              +param: ?number,
            |}>
          |}
        |},
        +corpoBill: ?({|
          +__typename: "Quote",
          +prices: ?{|
            +totalWithFees: ?{|
              +inclTax: ?number,
              +exclTax: ?number,
            |}
          |},
        |} | {|
          +__typename: "Proforma",
          +prices: ?{|
            +totalWithFees: ?{|
              +inclTax: ?number,
              +exclTax: ?number,
            |}
          |},
        |} | {|
          +__typename: "Invoice",
          +prices: ?{|
            +totalWithFees: ?{|
              +inclTax: ?number,
              +exclTax: ?number,
            |}
          |},
        |} | {|
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          +__typename: "%other"
        |}),
        +current: ?({|
          +__typename: "Quote",
          +prices: ?{|
            +totalWithFees: ?{|
              +inclTax: ?number,
              +exclTax: ?number,
            |}
          |},
        |} | {|
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          +__typename: "%other"
        |}),
      |}>,
    |}>,
  |}
|};
export type activitiesCardQuery = {|
  variables: activitiesCardQueryVariables,
  response: activitiesCardQueryResponse,
|};
*/


/*
query activitiesCardQuery(
  $options: JSONObject
) {
  bookingGetByCorpoRef(options: $options) {
    metadata
    data {
      keyMonth
      bookings {
        ref
        dates(format: "text")
        rider {
          fullUserName
          id
        }
        endAt
        spaceRef
        transactions(all: true) {
          paymentMethod
        }
        pdf {
          rider: getAvailablePdf(type: "rider") {
            list {
              slug
              text
              param
            }
          }
        }
        corpoBill {
          __typename
          ... on Quote {
            prices {
              totalWithFees {
                inclTax
                exclTax
              }
            }
            id
          }
          ... on Proforma {
            prices {
              totalWithFees {
                inclTax
                exclTax
              }
            }
            id
          }
          ... on Invoice {
            prices {
              totalWithFees {
                inclTax
                exclTax
              }
            }
            id
          }
        }
        current: corpoBill(typeDoc: "Quote") {
          __typename
          ... on Quote {
            prices {
              totalWithFees {
                inclTax
                exclTax
              }
            }
            id
          }
          ... on Invoice {
            id
          }
          ... on Proforma {
            id
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "options"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metadata",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyMonth",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ref",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "text"
    }
  ],
  "kind": "ScalarField",
  "name": "dates",
  "storageKey": "dates(format:\"text\")"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullUserName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spaceRef",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "all",
      "value": true
    }
  ],
  "concreteType": "BookingPayment",
  "kind": "LinkedField",
  "name": "transactions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentMethod",
      "storageKey": null
    }
  ],
  "storageKey": "transactions(all:true)"
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PdfQuery",
  "kind": "LinkedField",
  "name": "pdf",
  "plural": false,
  "selections": [
    {
      "alias": "rider",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "rider"
        }
      ],
      "concreteType": "AvailablePdfReturn",
      "kind": "LinkedField",
      "name": "getAvailablePdf",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "pdfTrad",
          "kind": "LinkedField",
          "name": "list",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "param",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "getAvailablePdf(type:\"rider\")"
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "BillPrices",
  "kind": "LinkedField",
  "name": "prices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OnePrice",
      "kind": "LinkedField",
      "name": "totalWithFees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inclTax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exclTax",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
],
v14 = {
  "kind": "InlineFragment",
  "selections": (v13/*: any*/),
  "type": "Quote",
  "abstractKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "typeDoc",
    "value": "Quote"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = [
  (v12/*: any*/),
  (v16/*: any*/)
],
v18 = {
  "kind": "InlineFragment",
  "selections": (v17/*: any*/),
  "type": "Quote",
  "abstractKey": null
},
v19 = [
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "activitiesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookingCorpoData",
        "kind": "LinkedField",
        "name": "bookingGetByCorpoRef",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingCorpo",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Booking",
                "kind": "LinkedField",
                "name": "bookings",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "rider",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "corpoBill",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v14/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v13/*: any*/),
                        "type": "Proforma",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v13/*: any*/),
                        "type": "Invoice",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "current",
                    "args": (v15/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "corpoBill",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": "corpoBill(typeDoc:\"Quote\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "activitiesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookingCorpoData",
        "kind": "LinkedField",
        "name": "bookingGetByCorpoRef",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingCorpo",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Booking",
                "kind": "LinkedField",
                "name": "bookings",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "rider",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "corpoBill",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v17/*: any*/),
                        "type": "Proforma",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v17/*: any*/),
                        "type": "Invoice",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "current",
                    "args": (v15/*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "corpoBill",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v18/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v19/*: any*/),
                        "type": "Invoice",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v19/*: any*/),
                        "type": "Proforma",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": "corpoBill(typeDoc:\"Quote\")"
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0cf2039561d97046f972311cc099ddb9",
    "id": null,
    "metadata": {},
    "name": "activitiesCardQuery",
    "operationKind": "query",
    "text": "query activitiesCardQuery(\n  $options: JSONObject\n) {\n  bookingGetByCorpoRef(options: $options) {\n    metadata\n    data {\n      keyMonth\n      bookings {\n        ref\n        dates(format: \"text\")\n        rider {\n          fullUserName\n          id\n        }\n        endAt\n        spaceRef\n        transactions(all: true) {\n          paymentMethod\n        }\n        pdf {\n          rider: getAvailablePdf(type: \"rider\") {\n            list {\n              slug\n              text\n              param\n            }\n          }\n        }\n        corpoBill {\n          __typename\n          ... on Quote {\n            prices {\n              totalWithFees {\n                inclTax\n                exclTax\n              }\n            }\n            id\n          }\n          ... on Proforma {\n            prices {\n              totalWithFees {\n                inclTax\n                exclTax\n              }\n            }\n            id\n          }\n          ... on Invoice {\n            prices {\n              totalWithFees {\n                inclTax\n                exclTax\n              }\n            }\n            id\n          }\n        }\n        current: corpoBill(typeDoc: \"Quote\") {\n          __typename\n          ... on Quote {\n            prices {\n              totalWithFees {\n                inclTax\n                exclTax\n              }\n            }\n            id\n          }\n          ... on Invoice {\n            id\n          }\n          ... on Proforma {\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a39c33ea36da2396023770dca45c3f09';

module.exports = node;
