/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
import type { cardSkinCorpo_item$ref } from "./cardSkinCorpo_item.graphql";
export type spaceCorpoActivitiesQueryVariables = {|
  lang: string,
  ref: string,
|};
export type spaceCorpoActivitiesQueryResponse = {|
  +one: ?{|
    +$fragmentRefs: cardSkinCorpo_item$ref
  |}
|};
export type spaceCorpoActivitiesQuery = {|
  variables: spaceCorpoActivitiesQueryVariables,
  response: spaceCorpoActivitiesQueryResponse,
|};
*/


/*
query spaceCorpoActivitiesQuery(
  $lang: String!
  $ref: String!
) {
  one: getSpaceByRef(ref: $ref) {
    ...cardSkinCorpo_item_3iqx2P
    id
  }
}

fragment cardSkinCorpo_item_3iqx2P on Space {
  title(lang: $lang)
  rate
  reviewCounter
  urlByLang(lang: $lang)
  pictures(count: 1) {
    ...image_space_picture
    id
  }
}

fragment image_space_picture on SpacePicture {
  nameId
  extension
  predictName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ref"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ref",
    "variableName": "ref"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "spaceCorpoActivitiesQuery",
    "selections": [
      {
        "alias": "one",
        "args": (v1/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpaceByRef",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "cardSkinCorpo_item"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "spaceCorpoActivitiesQuery",
    "selections": [
      {
        "alias": "one",
        "args": (v1/*: any*/),
        "concreteType": "Space",
        "kind": "LinkedField",
        "name": "getSpaceByRef",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reviewCounter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "kind": "ScalarField",
            "name": "urlByLang",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "count",
                "value": 1
              }
            ],
            "concreteType": "SpacePicture",
            "kind": "LinkedField",
            "name": "pictures",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extension",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "predictName",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": "pictures(count:1)"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "788fe0a4f13e6fa2b654d75a86063578",
    "id": null,
    "metadata": {},
    "name": "spaceCorpoActivitiesQuery",
    "operationKind": "query",
    "text": "query spaceCorpoActivitiesQuery(\n  $lang: String!\n  $ref: String!\n) {\n  one: getSpaceByRef(ref: $ref) {\n    ...cardSkinCorpo_item_3iqx2P\n    id\n  }\n}\n\nfragment cardSkinCorpo_item_3iqx2P on Space {\n  title(lang: $lang)\n  rate\n  reviewCounter\n  urlByLang(lang: $lang)\n  pictures(count: 1) {\n    ...image_space_picture\n    id\n  }\n}\n\nfragment image_space_picture on SpacePicture {\n  nameId\n  extension\n  predictName\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a91653c63211bf4436aad6c7203b579a';

module.exports = node;
