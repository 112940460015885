/* eslint-disable max-len */
import { useState, useEffect, memo } from 'react';
import { graphql } from 'react-relay';
import { nanoid } from 'nanoid';
import { DropDown, Select } from '@/core/dynamic';
import Icon from '@/core/icon';
// import Image from '@/core/image';
import { useTranslation } from '@/helpers/translate';
import Emitter from '@/helpers/emitter.js';
import Loading from '@/core/loading.js';
import CorpoMonthPagination from './activities/pagination.js';
import RowItem from './activities/bookingsByMonth.js';
import miniQLWS from '@/helpers/miniQueryLoaderv3.js';

const limitPage = 5;
const queryactivities = graphql`
  query activitiesCardQuery($options: JSONObject) {
    bookingGetByCorpoRef(options:$options) {
      metadata
      data {
        keyMonth
        bookings {
          ref
          dates(format: "text")
          rider {
            fullUserName
          }
          endAt
          spaceRef
          transactions(all: true) {
            paymentMethod
          }
          pdf {
            rider: getAvailablePdf(type: "rider") {
              list {
                slug
                text
                param
              }
            }
          }
          corpoBill {
            __typename
            ... on Quote {
              prices {
                totalWithFees {
                  inclTax
                  exclTax
                }
              }
            }
            ... on Proforma {
              prices {
                totalWithFees {
                  inclTax
                  exclTax
                }
              }
            }
            ... on Invoice {
              prices {
                totalWithFees {
                  inclTax
                  exclTax
                }
              }
            }
          }
          current: corpoBill(typeDoc:"Quote") {
            __typename
            ... on Quote {
              prices {
                totalWithFees {
                  inclTax
                  exclTax
                }
              }
            }
          }
        }
      }
    }
  }
`;

const cols = [
  {
    id: 1,
    label: 'corpo.booking.date',
  },
  {
    id: 3,
    label: 'corpo.activities.bookingcount',
  },
  {
    id: 4,
    label: 'corpo.price.totalExclTax',
  },
  {
    id: 2,
    label: 'corpo.price.totalInclTax',
  },
  // {
  //   id: 5,
  //   label: 'corpo.price.totalRemainInclTax',
  // },
];

export function Table({ columns, data, children }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState();
  const [isLoading, setLoading] = useState();

  useEffect(() => {
    Emitter.on('corpoactivitiesloading', setLoading);
    return () => {
      Emitter.off('corpoactivitiesloading', setLoading);
    };
  }, [data]);

  const toggleRow = (index) => {
    if (isOpen === index) {
      return setIsOpen();
    }
    return setIsOpen(index);
  };
  const headercolumns = columns.map((item) => (
    <HeaderColumn key={item.id} label={t(item.label)} />
  ));

  const rows = (data?.data || []).map((item, index) => (
    <RowItem key={item.keyMonth} item={item} open={isOpen === index} toggleRow={() => toggleRow(index)} />
  ));

  if (!data) return null;

  return (
    <div className="relative w-full h-full">
      {children}
      <div className="flex items-stretch w-full flex-nowrap h-9">{headercolumns}</div>
      {isLoading ? <div className="py-6 bg-white rounded-lg"><Loading /></div> : (
        <ul className="flex flex-col gap-y-2">{rows}</ul>
      )}

      {!data?.metadata?.[0] || <CorpoMonthPagination emitcode="corpoactivitiesrunquery" total={Math.ceil((data?.metadata?.[0].bkTotal || 0) / limitPage)} />}
    </div>
  );
}

export function HeaderColumn({ ...props }) {
  return (<div className="flex items-center justify-between w-full px-1 py-0 text-xs lg:px-3 text-dark lg:text-sm">{props.label}</div>);
}

function ActivitiesCardContent({ dataLoaded }) {
  const { t } = useTranslation();

  let years = (dataLoaded?.metadata?.[0]?.keyMonth || []).reduce((acc, keyM) => {
    const keyMs = keyM.split('_');
    const newAcc = [...acc];
    newAcc.push(parseInt(keyMs[0], 10));
    return newAcc;
  }, []);
  years = [...new Set(years)];

  const [filterYear] = useState(years.reduce((acc, i) => {
    acc.push({
      value: i,
      content: <span key={`corpoactivitiesfilteryear${i}`} className="block py-1 pl-3 pr-4 md:py-2 text-dark hover:text-black" role="menuitem">{i}</span>,
    });
    return acc;
  }, [{
    value: '',
    content: '',
  }]));

  const tmpfilterMonth = [{
    value: '',
    content: '',
  }];
  for (let tmpmonth = 0; tmpmonth < 12; tmpmonth += 1) {
    tmpfilterMonth.push({
      value: (tmpmonth + 1),
      content: (
        <span
          key={`corpoactivitiesfiltermonth${tmpmonth}`}
          className="block py-1 pl-3 pr-4 md:py-2 text-dark hover:text-black"
          role="menuitem"
        >
          {t(`date:month.${tmpmonth}`)}
        </span>),
    });
  }
  const [filterMonth] = useState(tmpfilterMonth);

  let ridersData = (dataLoaded?.metadata?.[0]?.ridersData || []).reduce((acc, riders) => {
    let newAcc = [...acc];
    newAcc = newAcc.concat(riders);
    return newAcc;
  }, []);

  ridersData = [...new Map(ridersData.map((item) => [item.ref, item])).values()];

  const [filterRiders] = useState(ridersData.reduce((acc, rider) => {
    const newAcc = [...acc];
    newAcc.push({
      value: rider.ref,
      content: <span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black" role="menuitem">{`${rider.firstname} ${rider.lastname}`}</span>,
    });
    return newAcc;
  }, [{
    value: '',
    content: '',
  }]));

  const onSelected = (field, value) => {
    console.log(field, value);
    Emitter.emit('corpoactivitiesrunquery', { [field]: value.value });
  };

  return (
    <div className="z-10 flex justify-center w-full">
      <div className="w-full flex-col md:w-[70v] lg:max-w-[1000px] px-6">
        <h2 className="pt-5 text-2xl text-left lg:text-3xl md:pt-10">
          {t('corpo:activities.title')}
        </h2>
        <div className="flex flex-col w-full px-4 py-3 mt-2 bg-white rounded-lg lg:mt-5 lg:p-8 shadow-light">
          <div className="grid justify-start grid-cols-1 gap-2 sm:grid-cols-2 md:flex md:gap-0 md:flex-row md:items-center md:mb-6">
            <div className="md:mr-2">
              <Select options={filterYear} onSelected={(value) => onSelected('year', value)} placeholder={<span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black">{t('date.year')}</span>} />
            </div>
            <div className="md:mr-6">
              <Select options={filterMonth} onSelected={(value) => onSelected('month', value)} placeholder={<span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black">{t('date.month.title')}</span>} />
            </div>
            <div className="md:mr-2">
              <Select options={filterRiders} onSelected={(value) => onSelected('riderRef', value)} placeholder={<span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black">{t('corpo.activities.team')}</span>} />
            </div>
            <div className="md:mr-2">
              <Select
                options={[{
                value: '',
                content: '',
              }, {
                value: 'cb',
                content: <span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black" role="menuitem">{t('corpo.paymentMode.cb')}</span>,
              }, {
                value: 'transfer',
                content: <span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black" role="menuitem">{t('corpo.paymentMode.transfer')}</span>,
              }]}
                onSelected={(value) => onSelected('paymentMethod', value)}
                placeholder={<span className="block py-1 pl-3 pr-4 text-base md:py-2 text-dark hover:text-black" role="menuitem">{t('corpo.paymentMode.title')}</span>}
              />

              {/* <DropDown
                compStyle="w-full"
                title={(
                  <a className="flex flex-row items-center justify-between py-2 pl-3 pr-4 overflow-hidden text-base border rounded-md w-54 border-white-dark4 ">
                    <p className="text-base font-normal truncate text-dark">{t('corpo:activities.payment_method')}</p>
                    <Icon name="arrow" className="w-6 h-4 ml-4 transform rotate-90 fill-current text-dark" />
                  </a>
                )}
                items={[
                  ,
                  ,
                ]}
              /> */}
            </div>
          </div>
          {!dataLoaded?.data?.length ?
            <div className="py-4 text-dark ld:py-0">
              {t('corpo.error.no_booking')}
            </div> : (
            <div className="w-full h-full mt-4 lg:mt-0">
              <Table data={dataLoaded} columns={cols} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ActivitiesCardEventContent() {
  const [dataLoaded, setDataLoaded] = useState();

  useEffect(() => {
    Emitter.on('corpoactivitiesloaded', setDataLoaded);
    return () => {
      Emitter.off('corpoactivitiesloaded', setDataLoaded);
    };
  }, [dataLoaded]);

  if (!dataLoaded) return null;

  return <ActivitiesCardContent {...{ dataLoaded }} />;
}

const ContainerRunQuery = miniQLWS({
  query: queryactivities,
  Component: ({ data, setRunQuery }) => {
    useEffect(() => {
     // Emitter.emit('corpoactivitiesloading', true);
      Emitter.emit('corpoactivitiesloading', false);
      console.log(data?.bookingGetByCorpoRef);
      Emitter.emit('corpoactivitiesloaded', (data?.bookingGetByCorpoRef || []));
      setRunQuery(null);
    }, [data]);
  },
  providerVariables: ({ options }) => ({ options }),
});

function ContainerEvent() {
  const [runQuery, setRunQuery] = useState(nanoid());
  const [options, setOptions] = useState({ limit: limitPage, page: 0 });

  const fnRerunQuery = ({
    page, riderRef, year, month, paymentMethod,
  }) => {
    let newOptions = { ...options };
    if (page !== null && page !== undefined) {
      newOptions = { ...newOptions, page };
    }
    if (riderRef !== null && riderRef !== undefined) {
      newOptions = { ...newOptions, page: 0, riderRef };
    }
    if (year !== null && year !== undefined) {
      newOptions = { ...newOptions, page: 0, year };
    }
    if (month !== null && month !== undefined) {
      newOptions = { ...newOptions, page: 0, month };
    }
    if (paymentMethod !== null && paymentMethod !== undefined) {
      newOptions = { ...newOptions, page: 0, paymentMethod };
    }
    setOptions({ ...newOptions });
    Emitter.emit('corpoactivitiesloading', true);
    setRunQuery(nanoid());
  };

  useEffect(() => {
    Emitter.on('corpoactivitiesrunquery', fnRerunQuery);
    return () => {
      Emitter.off('corpoactivitiesrunquery', fnRerunQuery);
    };
  }, [options]);

  return !runQuery || <ContainerRunQuery setRunQuery={setRunQuery} options={options} />;
}

const Container = memo(() => (
  <div className="flex items-center justify-center w-full">
    <ActivitiesCardEventContent />
    <ContainerEvent />
  </div>
));

export default Container;
